@font-face {
  font-family: 'Matoran';
  src: url('App/matoran.woff2') format('woff2'),
    url('App/matoran.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
#root {
  height: 100%;
}

html {
  background-color: #f2f2f2;
}
html.dark {
  background-color: #1c1c1c;
}

body::before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: url('App/background.svg');
  opacity: 0.025;
}

html.dark body::before {
  opacity: 0.1;
}

html.matoran-font .uses-matoran-font {
  font-family: 'Matoran', sans-serif;
  line-height: 1;
}

html.matoran-font .uses-matoran-font-title {
  font-size: 2rem;
}

html.matoran-font .uses-matoran-font-key {
  font-size: 1.25rem;
}
